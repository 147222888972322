import React, {useEffect, useRef, useState} from "react";
import {
    fetchFile,
} from "../../services/fileService";
import {useSelector} from "react-redux";
import {MessageComponent} from "../UI/MessageComponent";
import "./fileinput.css";
import "./imageinput.css";
import "./input.css";
import Resizer from "react-image-file-resizer";
import Functions from "../../utils/Functions";
import {Box, IconButton, Skeleton, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {MdDelete} from "react-icons/md";

export const ImageInput = (
    {
        file,
        value,
        onChange,
        formData,
        setFormData,
        objectAttachedType,
        canModify,
        setFileInfo = null,
        onFileChange = null,
        resizeImage = true,
        ...props
    }
) => {
    const token = useSelector((state) => state.auth.token);
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = React.useState({});
    const [isTextUploadVisible, setIsTextUploadVisible] = useState(true);
    const [isBtnDelVisible, setIsBtnDelVisible] = useState(false);
    const [imageUser, setImageUser] = useState(null);

    // Titre pour l'upload d'un fichier
    let textUpload = "";
    if (typeof props.textUpload !== "undefined") {
        textUpload = props.textUpload;
    } else {
        if (props.name.includes("picture") || props.name.includes("brief")) {
            textUpload = t("Click to upload a profile photo");
        } else {
            textUpload = t("Click to download a file");
        }
    }

    useEffect(() => {
        if (value !== undefined && value) {
            (async () => {
                try {
                    if (value) {
                        setLoading(true);
                    }

                    const picture = await fetchFile(
                        value,
                        Functions.TEXT_PROFILE_PICTURE,
                        token
                    );
                    if (picture?.error) {
                        // setMessage({type: "error", message: picture.error});
                    } else {
                        const urlImage = URL.createObjectURL(picture);
                        setImageUser(urlImage);
                        setIsTextUploadVisible(false);
                        setIsBtnDelVisible(true);
                    }

                    setLoading(false);
                } catch (error) {
                    // setMessage({type: "error", message: "Image supprimés ou déplacé."});
                }
            })();
        }
    }, [token, value]);

    const handleFileChange = async (e) => {
        try {
            let theFile = e.target.files[0];

            if (theFile && theFile.type.startsWith("image/")) {
                setIsTextUploadVisible(false);
                //Taille maximale d'une image autorisée
                const MAX_SIZE_InMB = 1;
                const sizeInBytes = theFile.size;
                const sizeInMb = (sizeInBytes / (1024 * 1024)).toFixed(2);

                if (sizeInMb > MAX_SIZE_InMB) {
                    Resizer.imageFileResizer(
                        theFile,
                        800, // Nouvelle largeur
                        800, // Nouvelle hauteur
                        "JPEG", // Format de sortie
                        75, // Qualité de sortie (entre 0 et 100)
                        0,
                        (blob) => {
                            const fileInfo = {
                                theFile: blob,
                                name: e.target.name,
                                objectAttachedType: objectAttachedType,
                            };
                            setFileInfo && setFileInfo(fileInfo);
                            onFileChange && onFileChange(props.name, fileInfo);

                            const urlImage = URL.createObjectURL(blob);
                            setImageUser(urlImage);
                        },
                        "blob" // Type de sortie ('blob' ou 'base64')
                    );
                } else {
                    const fileInfo = {
                        theFile: theFile,
                        name: e.target.name,
                        objectAttachedType: objectAttachedType,
                    };
                    setFileInfo && setFileInfo(fileInfo);
                    onFileChange && onFileChange(props.name, fileInfo);

                    const urlImage = URL.createObjectURL(theFile);
                    setImageUser(urlImage);
                }

                setIsBtnDelVisible(true);
            }

            setMessage({});
        } catch (error) {
            console.log(error);
            // setMessage({type: "error", message: error.message});
        }
    };

    const fileInputRef = useRef(null);
    const divTextUploadRef = useRef(null);
    const handleUploadClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDeleteFileClick = (e) => {
        e?.preventDefault();
        e?.stopPropagation();
        setFileInfo && setFileInfo(null);
        onFileChange && onFileChange(props.name, null);
        fileInputRef.current.value = null;
        setImageUser(null);
        setIsTextUploadVisible(true);
        setIsBtnDelVisible(false);
    };

    return (
        <div className={`input-column ${props.className}`}>
            <Box className="input-row" style={{justifyContent: {xs: "center", md: "start"}}}>
                <div
                    className={`file-upload input file-inputstandard ${props.className}`}
                    style={{display: "none"}}
                >
                    <input
                        type="file"
                        id="file-input"
                        className="file-input"
                        accept=".pdf, .doc, .docx, image/*"
                        max="20971520"
                        name={props.name}
                        onChange={(e) => handleFileChange(e)}
                        disabled={!canModify}
                        ref={fileInputRef}
                    />
                </div>

                {loading && (
                    <Skeleton
                        variant="rounded"
                        className="file-image-skeleton"
                    />
                )}
                {!loading && (
                    <Stack
                        direction="column"
                        alignItems="center"
                        spacing={0.2}
                    >
                        <div className={`box-file ${props.error ? "error" : ""}`} onClick={handleUploadClick}>
                            {isTextUploadVisible &&
                                <Typography
                                    variant="label"
                                    sx={{color: "#7b7f8b", fontSize: "14px", textAlign: "center"}}
                                >
                                    <div className="text-wrap" ref={divTextUploadRef}>
                                        {textUpload}
                                    </div>
                                </Typography>
                            }
                            {imageUser && (
                                <Box>
                                    <img
                                        className="file-image"
                                        src={imageUser}
                                        alt="Uploaded"
                                        style={{maxWidth: "100%", maxHeight: "148px"}}
                                    />
                                </Box>
                            )}
                            {isBtnDelVisible && (
                                <Box sx={{position: "absolute", top: 2, right: 2}}>
                                    <IconButton
                                        title={t("Delete")}
                                        onClick={handleDeleteFileClick}
                                        size="small"
                                        color="secondary"
                                    >
                                        <MdDelete size={20}/>
                                    </IconButton>
                                </Box>
                            )}
                        </div>
                    </Stack>
                )}
            </Box>

            {
                message?.type &&
                <MessageComponent
                    type={message.type}
                    message={message.message}
                    width="100%"
                />
            }
        </div>
    );
};
