import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {Box, Button, Chip, Popover, Stack, Typography} from "@mui/material";
import Functions from "../../../utils/Functions";
import Popup from "../../../components/Popup/Popup";
import InvoiceSendFileForm from "../../../components/Forms/Mission/Sections/InvoiceSection/InvoiceSendFileForm";
import {modifyManyInvoiceStatus} from "../../../services/invoiceService";

export const SelectedInvoiceFooter = ({selectedInvoices, onChangeStatus, onSendInvoice, onCancelSelecting}) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [showSendInvoiceDialog, setShowSendInvoiceDialog] = useState(false);

    const handleStatusChange = (status) => {
        handleMenuClose();
        onChangeStatus(status);
    };

    const handleCancelSelecting = () => {
        onCancelSelecting();
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openMenu = Boolean(anchorEl);
    const id = openMenu ? 'change-status-popover' : undefined;

    return (
        <>
            {selectedInvoices.length > 0 && (
                <Box id="selectedItemFooter">
                    <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
                        <Typography sx={{fontWeight: "bold", textTransform: "lowercase"}}>
                            {selectedInvoices.length} {t("Invoice(s)")}
                        </Typography>
                        <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                            <>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleMenuClick}
                                    size="small"
                                >
                                    {t("Change status")}
                                </Button>
                                <Popover
                                    id={id}
                                    open={openMenu}
                                    anchorEl={anchorEl}
                                    onClose={handleMenuClose}
                                    anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                                    transformOrigin={{vertical: 'bottom', horizontal: 'left'}}
                                    sx={{mt: -1.5}}
                                >
                                    <Stack direction="column" spacing={1} sx={{p: 1.5, width: "157px"}}>
                                        {['Validated', 'Paid', 'Rejected'].map(status => {
                                            const label = t(Functions.STATUS_INVOICE[status]);
                                            return (
                                                <Chip
                                                    key={status}
                                                    label={`${label}(s)`}
                                                    title={`${label}(s)`}
                                                    size="small"
                                                    className={Functions.STATUS_INVOICE_BG[status]}
                                                    sx={{whiteSpace: "nowrap", cursor: "pointer"}}
                                                    onClick={() => handleStatusChange(status)}
                                                />
                                            );
                                        })}
                                    </Stack>
                                </Popover>
                            </>
                            <>
                                <Button
                                    aria-describedby={id}
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setShowSendInvoiceDialog(true)}
                                    size="small"
                                >
                                    {t("Send by e-mail")}
                                </Button>
                                <Popup
                                    open={showSendInvoiceDialog}
                                    title={t("Send by e-mail")}
                                    onDialogClose={() => setShowSendInvoiceDialog(false)}
                                >
                                    <InvoiceSendFileForm
                                        invoiceIds={selectedInvoices}
                                        onSaveFinish={onSendInvoice}
                                        onClose={() => setShowSendInvoiceDialog(false)}
                                    />
                                </Popup>
                            </>
                            <Button
                                variant="outlined"
                                color="light"
                                onClick={handleCancelSelecting}
                                size="small"
                            >
                                {t("Cancel")}
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            )}

        </>
    );
};

export const changeManyInvoiceStatus = async ({invoiceIds, status, token, callback}) => {
    if (!invoiceIds.length) return;

    const invoiceDatas = invoiceIds.map((invoiceId) => {
        const item = {id: invoiceId, status};
        switch (status) {
            case Functions.TEXT_CANCELED:
            case Functions.TEXT_REJECTED:
                return {...item, canceledDate: new Date()};
            case Functions.TEXT_VALIDATED:
                return {...item, validatedDate: new Date()};
            case Functions.TEXT_PAID:
                return {...item, paymentDate: new Date()};
            default:
                return item;
        }
    });
    const response = await modifyManyInvoiceStatus({invoices: invoiceDatas}, token);
    response.data = invoiceDatas;
    callback && callback(response);
};