import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {fetchFreelanceProfiles} from "../../services/freelanceProfileService";
import Grid2 from "@mui/material/Unstable_Grid2";
import {Box, Card, CardContent, Stack, TablePagination, Typography} from "@mui/material";
import "./contingentworkercard.css";
import {useNavigate} from "react-router-dom";
import {CustomTagCell} from "../../components/Tables/CustomTagCell";
import _ from "lodash";
import S3Image from "../../components/UI/S3Image";
import {FreelanceAvailabilityDisplay} from "../../components/Forms/FreelanceProfile/FreelanceAvailabilityDisplay";
import Utils from "../../utils/Utils";
import {Display} from "../../components/Inputs/Display";
import {FavoriteButton} from "./PoolBoard";
import {addFreelanceToFavorite, removeFreelanceToFavorite} from "../../services/enterpriseProfileService";
import {MdRemove} from "react-icons/md";
import Popup from "../../components/Popup/Popup";
import {
    FreelancePortfolioDetail
} from "../../components/Forms/FreelanceProfile/Sections/ProfileSection/Card/FreelancePortfolioCard";

export const ContingentWorkerCard = (
    {
        rows,
        setRows,
        setRowCount,
        displayConfig,
        hideFooter,
        params = {},
        onPaginationChange
    }
) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const token = useSelector((state) => state.auth.token);

    const [freelanceProfiles, setFreelanceProfiles] = useState([]);
    const [showPortfolioDetailDialog, setShowPortfolioDetailDialog] = useState(false);
    const [activeFreelanceProfile, setActiveFreelanceProfile] = useState({});
    const [activePortfolio, setActivePortfolio] = useState(null);

    useEffect(() => {
        setFreelanceProfiles(rows);
    }, [rows]);

    const handleFreelancePortfolioClick = (freelanceProfile) => {
        setActivePortfolio(null);
        setActiveFreelanceProfile(freelanceProfile);
        setShowPortfolioDetailDialog(true);
    };

    const handleToggleFavorite = async (id, isFavorite) => {
        const updatedData = _.cloneDeep(rows);
        const action = isFavorite ? addFreelanceToFavorite : removeFreelanceToFavorite;

        if (!isFavorite && displayConfig?.displayType === 'MyFavorites') {
            const index = rows.findIndex(freelance => freelance.id === id);
            if (index === -1) return;

            updatedData.splice(index, 1);
            setRows(updatedData);
            setRowCount(updatedData.length);
        }

        await action(id, token);
    };

    const handleFreelanceProfileClick = ({id}) => {
        navigate(`/pool/contingent-worker/${id}`);
    };

    const handleChangePage = (event, newPage) => {
        onPaginationChange?.({...params, page: newPage});
    };

    const handleChangeRowsPerPage = (event) => {
        onPaginationChange?.({...params, page: 0, pageSize: parseInt(event.target.value, 10)});
    };

    return (
        <>
            <Box sx={{flexGrow: 1, pr: 1, overflow: "hidden auto"}}>
                <Grid2 container justifyContent="start" spacing={3}>
                    {freelanceProfiles.map((freelanceProfile) => {
                        const user = freelanceProfile?.User;
                        user.picture = user?.picture ?? "default";

                        const customOrder = {technical: 1, transversal: 2};
                        let skills = freelanceProfile?.FreelanceProfileSkills.map((item) => item.Skill);
                        skills = skills.filter(skill => skill.type === 'technical' || skill.type === 'transversal');
                        skills = _.orderBy(skills, skill => customOrder[skill.type]);
                        const tags = skills.map(tag => tag.name);

                        const availability = freelanceProfile.nextAvailable || freelanceProfile.nextNotAvailable;
                        const lastPortfolio = freelanceProfile?.lastPortfolio;

                        return (
                            <Grid2 xs={6} lg={12 / 5}>
                                <Box
                                    className="freelance-profile-card"
                                    onDoubleClick={() => handleFreelanceProfileClick(freelanceProfile)}
                                >
                                    <Card className="w-100 h-100">
                                        <Box
                                            className="MuiCardMedia-root"
                                            sx={{height: "90px", backgroundColor: "var(--info-light-color)"}}
                                        >
                                            {lastPortfolio && (
                                                <Box
                                                    className="w-100 h-100"
                                                    onClick={() => handleFreelancePortfolioClick(freelanceProfile)}
                                                >
                                                    <Display type="image" value={lastPortfolio?.content}/>
                                                </Box>
                                            )}
                                        </Box>
                                        <CardContent>
                                            <Stack gap={1.5}>
                                                <Grid2 container spacing={1.5}
                                                       sx={{position: "relative", height: "47px"}}>
                                                    <Grid2
                                                        xs="auto"
                                                        className={Utils.getAvailabilityClass(availability?.type)}
                                                    >
                                                        <S3Image value={user?.picture} displaySkeleton={true}/>
                                                    </Grid2>
                                                    <Grid2 xs sx={{ml: "80px"}}>
                                                        <FreelanceAvailabilityDisplay
                                                            availabilityData={freelanceProfile}
                                                            icon={false}
                                                            label={{notSpecified: 'Availabilities not specified'}}
                                                        />
                                                    </Grid2>
                                                </Grid2>
                                                <Stack>
                                                    <Typography variant="textBold"
                                                                className="Mui-textTruncate">{user?.fullName}</Typography>
                                                    <Typography className="Mui-textTruncate">
                                                        {freelanceProfile?.jobTitle || "-"}
                                                    </Typography>
                                                </Stack>
                                                <Stack>
                                                    <Typography
                                                        className="Mui-textTruncate">{user?.phoneNumber}</Typography>
                                                    <Typography className="Mui-textTruncate">{user?.email}</Typography>
                                                </Stack>
                                                <Box sx={{width: "100%", maxHeight: "58px", overflow: "hidden"}}>
                                                    <CustomTagCell value={tags}/>
                                                </Box>
                                            </Stack>
                                            <Box sx={{position: "absolute", top: "5px", right: "5px"}}>
                                                <FavoriteButton
                                                    id={freelanceProfile.id}
                                                    isFavorite={freelanceProfile?.EnterpriseFreelanceFavorites?.length > 0}
                                                    onToggleFavorite={handleToggleFavorite}
                                                />
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid2>
                        );
                    })}
                </Grid2>
                <Popup
                    open={showPortfolioDetailDialog}
                    onDialogClose={() => setShowPortfolioDetailDialog(false)}
                    dialogCloseIcon={<MdRemove/>}
                >
                    <Box sx={{width: {md: "65vw"}}}>
                        <Stack direction="column" justifyContent="start" gap={3}>
                            <Typography variant="label">
                                {
                                    t("Portfolio of {{freelanceFirstName}}", {
                                        freelanceFirstName: activeFreelanceProfile?.User?.firstName
                                    })
                                }
                            </Typography>
                            <FreelancePortfolioDetail
                                freelanceProfile={activeFreelanceProfile}
                                setActivePortfolio={setActivePortfolio}
                                activePortfolio={activePortfolio}
                            />
                        </Stack>
                    </Box>
                </Popup>
            </Box>
            {!hideFooter && (
                <Stack justifyContent="center">
                    <TablePagination
                        component="div"
                        count={params.rowCount}
                        page={params.page}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[20, 50, 100]}
                        rowsPerPage={params.pageSize}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Lignes par page :"
                        labelDisplayedRows={({from, to, count}) =>
                            `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`
                        }
                    />
                </Stack>
            )}
        </>
    );
};