import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {Table} from "../../components/Tables/Table";
import Functions from "../../utils/Functions";
import {fetchProposalMissions} from "../../services/missionService";
import {Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import PermissionService from "../../services/permissionService";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const ProposalAssignmentBoard = ({onListChange}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const sessionType = connectedUser.sessionType;
    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    const [loading, setLoading] = useState(true);
    const [rowCount, setRowCount] = useState(0);
    const [data, setData] = useState([]);

    const defaultFilterConfigs = {
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    };
    const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);

    const columns = [
        {
            field: 'Mission.name',
            headerName: t("Assignment"),
            type: 'string',
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => row.name
        },
        {
            field: 'User.fullName',
            type: 'string',
            headerName: t("Proposal sender 2"),
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                return row?.User?.fullName;
            }
        },
        {
            field: 'proposalCount',
            type: 'number',
            noSearchFilter: true,
            headerName: t("Contingent workers having received the proposal"),
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return (
                    <Typography>
                        {parseInt(row.proposalCount) > 0 ? row.proposalCount : "-"}
                    </Typography>
                );
            }
        },
        {
            field: 'interestedFreelanceCount',
            type: 'number',
            noSearchFilter: true,
            headerName: t("Contingent workers interested in the assignment"),
            minWidth: 200,
            flex: 2.0,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return (
                    <Typography>
                        {parseInt(row.interestedFreelanceCount) > 0 ? row.interestedFreelanceCount : "-"}
                    </Typography>
                );
            }
        }
    ];

    const loadAssignments = useCallback((query = {}) => {
        if (!isEnterpriseSession) return;

        setLoading(true);
        try {
            fetchProposalMissions(query, token).then((fetchedData) => {
                if (!fetchedData?.error) {
                    setRowCount(fetchedData.count);
                    setData(fetchedData.data);
                    onListChange?.(fetchedData.count);
                }
            }).finally(() => setLoading(false));
        } catch (error) {
            console.error(error);
        }
    }, [token, isEnterpriseSession]);

    useEffect(() => {
        loadAssignments(filterConfigs);
    }, [filterConfigs]);

    const handleDataGridChange = (filterConfigs) => {
        setFilterConfigs(filterConfigs);
    };

    const handleCellClick = (row) => {
        if (PermissionService.isGranted(connectedUser, Functions.MODULE.STAFFING)) {
            navigate(`/pool/proposalmission/${row.id}`);
        }
    };

    return (
        <Table
            loading={loading}
            columns={columns}
            data={data}
            rowCount={rowCount}
            pageSize={pageSize}
            filterConfigs={filterConfigs}
            onChange={handleDataGridChange}
            onCellClick={handleCellClick}
        />
    );
};

