import React from "react";
import {Button, Menu, MenuItem, Stack, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import {MdCheck, MdKeyboardArrowDown} from "react-icons/md";
import ResponsiveComponent from "./ResponsiveComponent";
import "./sectionmenu.css";

const SectionMenu = (
    {
        color = "primary",
        sectionMenuItems,
        finishedSections = [],
        activeSection,
        onSectionMenuClick
    }
) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const sectionMenuOpen = Boolean(anchorEl);

    const handleSectionMenuClick = (section) => {
        setAnchorEl(null);
        onSectionMenuClick && onSectionMenuClick(section);
    };

    const handleMenuClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const getMenuIndex = (key) => {
        return sectionMenuItems.findIndex(item => item.key === key);
    };

    return <>
        <ResponsiveComponent mobile>
            <Button
                aria-controls={sectionMenuOpen ? 'sectionMenu' : undefined}
                aria-haspopup="true"
                aria-expanded={sectionMenuOpen ? 'true' : undefined}
                variant="contained"
                color={color}
                disableElevation
                onClick={handleMenuClick}
                endIcon={<MdKeyboardArrowDown/>}
                sx={{minWidth: "100%", width: "100%"}}
            >
                {t(sectionMenuItems[getMenuIndex(activeSection)]?.name)}
            </Button>
            <Menu
                id="sectionMenu"
                anchorEl={anchorEl}
                open={sectionMenuOpen}
                onClose={handleMenuClose}
            >
                {sectionMenuItems.map((menu) => (
                    <MenuItem
                        key={menu.key}
                        onClick={() => handleSectionMenuClick(menu.key)}
                        sx={{width: "100%"}}
                        disableRipple
                    >
                        {t(menu.name)}
                    </MenuItem>
                ))}
            </Menu>
        </ResponsiveComponent>

        <ResponsiveComponent tablet desktop>
            <ul className="sectionlist">
                {sectionMenuItems.map((menu) => (
                    <li key={menu.key}>
                        <Tooltip
                            title={menu.tooltip}
                            arrow
                            placement="right"
                        >
                            <div
                                onClick={() => handleSectionMenuClick(menu.key)}
                                className={`section-item section-item-${color} ${activeSection === menu.key ? "active" : ""}`}
                                id={`sectionItem${menu.key}`}
                            >
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    {t(menu.name)}
                                    {finishedSections && finishedSections.includes(menu.key) &&
                                        <MdCheck className="section-item-icon"/>
                                    }
                                </Stack>
                            </div>
                        </Tooltip>
                    </li>
                ))}
            </ul>
        </ResponsiveComponent>
    </>
}

export default SectionMenu