import {useTranslation} from "react-i18next";
import {Box, Stack, Typography} from "@mui/material";
import {MdCircle} from "react-icons/md";
import Utils from "../../../utils/Utils";
import React, {memo} from "react";
import "./freelanceavailabilitydisplay.css";
import _ from "lodash";

export const FreelanceAvailabilityDisplay = memo((
    {
        icon = true,
        availabilityData,
        label = {notSpecified: 'Not specified'}
    }
) => {
    const {t} = useTranslation();

    const getAvailabilityDisplay = () => {
        const availability = availabilityData.nextAvailable || availabilityData.nextNotAvailable;
        return availability ?
            availabilityStatus(availability)
            : (<Box className="freelance-availability-display">{t(label?.notSpecified)}</Box>);
    };

    const availabilityStatus = ({type, date}) => {
        const colorStatuses = {
            'Available': 'available',
            'Partially available': 'partially-available',
            'Not available': 'not-available',
        };
        const colorStatus = colorStatuses[type] || 'default';

        return (
            <Stack
                direction="column"
                alignItems="center"
                className="freelance-availability-display"
                sx={{width: "100%", fontSize: "14px"}}
            >
                {type &&
                    <Typography variant="iconText">
                        {icon && (
                            <MdCircle className={`availability-status-${colorStatus}`} size={14}
                                      style={{marginRight: "3px"}}/>
                        )}
                        {t(type)}
                    </Typography>
                }
                {type !== 'Not available' &&
                    <Stack direction="row" className="freelance-availability-date" spacing={0.5}>
                        <div>{t("From")} {Utils.formatDate(date)}</div>
                    </Stack>
                }
            </Stack>
        );
    };

    return (
        <>{getAvailabilityDisplay()}</>
    );
}, (prevProps, nextProps) => {
    return _.isEqual(prevProps.availabilityData, nextProps.availabilityData);
});