import React, {useState, useEffect} from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
    useLocation,
} from "react-router-dom";

import {useSelector} from "react-redux";

import {Index} from "../pages/Index";
import {ResetPassword} from "../pages/ResetPassword";
import {FreelanceProfile} from "../pages/ContingentWorker/FreelanceProfile";
import {MissionsBoard} from "../pages/Mission/MissionsBoard";
import {MissionsDetail} from "../pages/Mission/MissionsDetail";
import {Configuration} from "../pages/Configuration";
import {ContractsBoard} from "../pages/Mission/Contract/ContractsBoard";
import {Onboarding} from "../pages/Onboarding";
import {InvoicesBoard} from "../pages/Mission/Invoice/InvoicesBoard";
import {PublicContingentWorker} from "../pages/Public/PublicContingentWorker";
import {PublicAssessment} from "../pages/Public/PublicAssessment";
import {FreelanceComparator} from "../pages/ContingentWorker/FreelanceComparator";
import {PublicMission} from "../pages/Public/PublicMission";
import {ContractDetail} from "../pages/Mission/Contract/ContractDetail";
import {InvoiceDetail} from "../pages/Mission/Invoice/InvoiceDetail";
import {ReportingBoard} from "../pages/ReportingBoard";
import PermissionService from "../services/permissionService";
import QuotesBoard from "../pages/Mission/Quote/QuotesBoard";
import {QuoteDetail} from "../pages/Mission/Quote/QuoteDetail";
import {CompanyBoard} from "../pages/Company/CompanyBoard";
import {SourcingBoard} from "../pages/Sourcing/SourcingBoard";
import {SourcingFreelanceProfile} from "../pages/Sourcing/SourcingFreelanceProfile";
import {OfferBoard} from "../pages/Recruitment/Offer/OfferBoard";
import {OfferDetail} from "../pages/Recruitment/Offer/OfferDetail";
import {CandidateBoard} from "../pages/Recruitment/Candidate/CandidateBoard";
import {CandidateDetail} from "../pages/Recruitment/Candidate/CandidateDetail";
import {JobApplicationBoard} from "../pages/Recruitment/JobApplication/JobApplicationBoard";
import {JobApplicationDetail} from "../pages/Recruitment/JobApplication/JobApplicationDetail";
import {AffiliationBoard} from "../pages/Collaboration/Affiliation/AffiliationBoard";
import {CooptationBoard} from "../pages/Collaboration/Cooptation/CooptationBoard";
import {PublicOffer} from "../pages/Public/Recruitment/PublicOffer";
import {PublicOfferOnboarding} from "../pages/Public/Recruitment/PublicOfferOnboarding";
import {FreelanceApplicationBoard} from "../pages/Recruitment/JobApplication/FreelanceApplicationBoard";
import MainLayout from "../layouts/MainLayout";
import Functions from "../utils/Functions";
import {PoolBoard} from "../pages/ContingentWorker/PoolBoard";

const ProtectedRoute = ({element, redirectTo, isGranted = true}) => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const [lastVisitedPage, setLastVisitedPage] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname !== "/") {
            if (!isAuthenticated) {
                setLastVisitedPage(`${location.pathname}${location.search}`);
            } else if (!isGranted) {
                setLastVisitedPage("/");
            }
        }
    }, [isAuthenticated, isGranted, location]);

    return isAuthenticated && isGranted ? (
        element
    ) : lastVisitedPage ? (
        <Navigate to={redirectTo || "/"} state={{lastVisitedPage}}>
            {element}
        </Navigate>
    ) : null;
};

export const AppRouter = () => {
    const connectedUser = useSelector((state) => state.auth);

    return (
        <Router>
            <Routes>
                <Route element={<MainLayout/>}>
                    <Route path="/configuration" element={<ProtectedRoute element={<Configuration/>} redirectTo="/"/>}/>
                    <Route
                        path="/companies/companiesboard"
                        element={<ProtectedRoute element={<CompanyBoard/>} redirectTo="/"/>}
                    />
                    <Route path="/affiliation"
                           element={<ProtectedRoute element={<AffiliationBoard/>} redirectTo="/"/>}/>
                    <Route path="/cooptation" element={<ProtectedRoute element={<CooptationBoard/>} redirectTo="/"/>}/>
                    <Route
                        path="/pool/comparator"
                        element={
                            <ProtectedRoute
                                element={<FreelanceComparator/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.STAFFING)}
                            />
                        }
                    />
                    <Route
                        path="/pool/contingent-worker/:id"
                        element={<ProtectedRoute element={<FreelanceProfile/>} redirectTo="/"/>}
                    />
                    <Route
                        path="/pool/proposalmission/:id"
                        element={
                            <ProtectedRoute
                                element={<MissionsDetail proposal={true}/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.STAFFING)}
                            />
                        }
                    />
                    <Route
                        path="/pool/card/myfavorites"
                        element={<ProtectedRoute element={<PoolBoard viewType="Card" subViewType="MyFavorites"/>}
                                                 redirectTo="/"/>}
                    />
                    <Route
                        path="/pool/card/proposalmission"
                        element={
                            <ProtectedRoute
                                element={<PoolBoard viewType="Card" subViewType="ProposalMission"/>}
                                redirectTo="/"
                            />
                        }
                    />
                    <Route
                        path="/pool/card/all?"
                        element={<ProtectedRoute element={<PoolBoard viewType="Card" subViewType="All"/>}
                                                 redirectTo="/"/>}
                    />
                    <Route
                        path="/pool/list/myfavorites"
                        element={<ProtectedRoute element={<PoolBoard viewType="List" subViewType="MyFavorites"/>}
                                                 redirectTo="/"/>}
                    />
                    <Route
                        path="/pool/list/proposalmission"
                        element={
                            <ProtectedRoute
                                element={<PoolBoard viewType="List" subViewType="ProposalMission"/>}
                                redirectTo="/"
                            />
                        }
                    />
                    <Route
                        path="/pool/list/all?"
                        element={<ProtectedRoute element={<PoolBoard viewType="List" subViewType="All"/>}
                                                 redirectTo="/"/>}
                    />
                    <Route
                        path="/profile"
                        element={<ProtectedRoute element={<FreelanceProfile/>} redirectTo="/"/>}
                    />
                    <Route
                        path="/sourcing/sourcingboard"
                        element={
                            <ProtectedRoute
                                element={<SourcingBoard/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ATS)}
                            />
                        }
                    />
                    <Route
                        path="/sourcing/contingent-worker/:id"
                        element={
                            <ProtectedRoute
                                element={<SourcingFreelanceProfile/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ATS)}
                            />
                        }
                    />
                    <Route
                        path="/recruitment/applications"
                        element={
                            <ProtectedRoute
                                element={<JobApplicationBoard/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ATS)}
                            />
                        }
                    />
                    <Route
                        path="/applications/applicationsboard"
                        element={
                            <ProtectedRoute
                                element={<FreelanceApplicationBoard/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ATS)}
                            />
                        }
                    />
                    <Route
                        path="/recruitment/applications/detail/:id?"
                        element={
                            <ProtectedRoute
                                element={<JobApplicationDetail/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ATS)}
                            />
                        }
                    />
                    <Route
                        path="/recruitment/offers"
                        element={
                            <ProtectedRoute
                                element={<OfferBoard/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ATS)}
                            />
                        }
                    />
                    <Route
                        path="/recruitment/offers/detail/:id?"
                        element={
                            <ProtectedRoute
                                element={<OfferDetail/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ATS)}
                            />
                        }
                    />
                    <Route
                        path="/recruitment/candidates"
                        element={
                            <ProtectedRoute
                                element={<CandidateBoard/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ATS)}
                            />
                        }
                    />
                    <Route
                        path="/recruitment/candidates/detail/:id?"
                        element={
                            <ProtectedRoute
                                element={<CandidateDetail/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ATS)}
                            />
                        }
                    />
                    <Route
                        path="/missions/missionsboard"
                        element={
                            <ProtectedRoute
                                element={<MissionsBoard/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ASSIGNMENT)}
                            />
                        }
                    />
                    <Route
                        path="/missions/missionsdetail/:id?"
                        element={
                            <ProtectedRoute
                                element={<MissionsDetail/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ASSIGNMENT)}
                            />
                        }
                    />
                    <Route
                        path="/quotes/quotesboard"
                        element={
                            <ProtectedRoute
                                element={<QuotesBoard/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ASSIGNMENT)}
                            />
                        }
                    />
                    <Route
                        path="/quotes/:id"
                        element={
                            <ProtectedRoute
                                element={<QuoteDetail/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ASSIGNMENT)}
                            />
                        }
                    />
                    <Route
                        path="/contracts/contractsboard"
                        element={
                            <ProtectedRoute
                                element={<ContractsBoard/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ASSIGNMENT)}
                            />
                        }
                    />
                    <Route
                        path="/contracts/:id"
                        element={
                            <ProtectedRoute
                                element={<ContractDetail/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ASSIGNMENT)}
                            />
                        }
                    />
                    <Route
                        path="/invoices/invoicesboard"
                        element={
                            <ProtectedRoute
                                element={<InvoicesBoard/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ASSIGNMENT)}
                            />
                        }
                    />
                    <Route
                        path="/invoices/:id"
                        element={
                            <ProtectedRoute
                                element={<InvoiceDetail/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.ASSIGNMENT)}
                            />
                        }
                    />
                    <Route
                        path="/reporting"
                        element={
                            <ProtectedRoute
                                element={<ReportingBoard/>}
                                redirectTo="/"
                                isGranted={PermissionService.isGranted(connectedUser, Functions.MODULE.REPORTING)}
                            />
                        }
                    />
                </Route>

                <Route path="/public/mission/:id" element={<PublicMission/>}/>
                <Route path="/public/evaluation/:id" element={<PublicAssessment/>}/>
                <Route path="/public/contingent-worker/:id" element={<PublicContingentWorker profileSharing={true}/>}/>
                <Route path="/profile/:id" element={<PublicContingentWorker/>}/>
                <Route path="/offer/:id" element={<PublicOffer/>}/>
                <Route path="/offer/onboarding/:id" element={<PublicOfferOnboarding/>}/>
                <Route path="/reset-password/:token" element={<ResetPassword/>}/>
                <Route path="/onboarding" element={<ProtectedRoute element={<Onboarding/>} redirectTo="/"/>}/>

                {/* <Route path="/directauthentification/:token" element={<DirectAuthentification />} /> */}
                <Route path="/" element={<Index/>}/>
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        </Router>
    );
};
