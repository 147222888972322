import {useTranslation} from "react-i18next";
import {FieldHolder} from "../../../FieldHolder";
import {
    Box,
    Button, Chip,
    Grid,
    IconButton,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useMediaQuery, useTheme
} from "@mui/material";
import Functions from "../../../../../utils/Functions";
import React, {useEffect, useRef, useState} from "react";
import {fetchEnterpriseProfiles} from "../../../../../services/enterpriseProfileService";
import {useSelector} from "react-redux";
import {FreelanceSelectionPopup} from "../../../../Popup/FreelanceSelectionPopup";
import {UserCard} from "../../../../UI/UserCard";
import skillService from "../../../../../services/skillService";
import {Table} from "../../../../Tables/Table";
import {
    deleteShortlistedFreelance,
    fetchMissionShortlistedFreelances, saveShortlistedFreelance
} from "../../../../../services/missionShortlistedFreelance";
import Utils from "../../../../../utils/Utils";
import {MdDelete} from "react-icons/md";
import Popup from "../../../../Popup/Popup";
import {SendProposalAssignmentForm} from "./SendProposalAssignmentForm";
import {useToast} from "../../../../../context/ToastProvider";
import {CustomCard} from "../../../../UI/CustomCard";
import TagService from "../../../../../services/tagService";
import PermissionService from "../../../../../services/permissionService";

const pageSize = parseInt(process.env.REACT_APP_TABLE_DEFAULT_PAGE_SIZE);

export const MissionDetailSection = (
    {
        missionId,
        formData,
        company,
        onSave,
        sessionType,
        onMissionFormatChange,
        canModify,
        proposal
    }
) => {
    const {t} = useTranslation();
    const showToast = useToast();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);

    const [showProposeMissionDialog, setShowProposeMissionDialog] = useState(false);
    const [showFreelanceSelectionDialog, setShowFreelanceSelectionDialog] = useState(false);

    const [editMode, setEditMode] = useState(canModify && !missionId);
    const [sectionFormData, setSectionFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [missionManagers, setMissionManagers] = useState([]);
    const [tags, setTags] = useState([]);
    const [skillsData, setSkillsData] = useState([]);
    const [shortlistedFreelances, setShortlistedFreelances] = useState([]);
    const [selectedShortlistedFreelances, setSelectedShortlistedFreelances] = useState([]);

    const [shortlistedFreelancesRowSelectionModel, setShortlistedFreelancesRowSelectionModel] = useState([]);

    const fetchMissionShortlistedFreelancesRef = useRef();
    const [rowCount, setRowCount] = useState(0);
    const [filterConfigs, setFilterConfigs] = useState({
        sort: '',
        range: JSON.stringify([0, pageSize - 1]),
        filter: ''
    });

    const descriptionRef = useRef(null);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isEnterpriseSession = sessionType === Functions.SESSION_TYPE_ENTERPRISE;
    const isSuperAdministrator = PermissionService.hasRole(connectedUser, Functions.USER_ROLE.ADMIN);

    const columns = [
        {
            field: 'FreelanceProfile.User.fullName',
            type: 'string',
            headerName: t("Name"),
            minWidth: 180,
            flex: 1.8,
            headerAlign: 'center',
            align: 'left',
            renderCell: ({row}) => {
                const user = row?.FreelanceProfile?.User;
                return user ? <UserCard user={user} displaySkeleton={true}/> : <></>
            }
        },
        {
            field: 'proposalDate',
            type: 'string',
            headerName: t("Proposed assignment"),
            minWidth: 180,
            flex: 1.8,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params, row) => row.proposalDate && new Date(row.proposalDate),
            renderCell: ({row}) => {
                return row.proposalDate ? t('Yes') + " - " + Utils.formatDate(row.proposalDate) : t('No');
            }
        },
        {
            field: 'ProposalSender.User.fullName',
            type: 'string',
            headerName: t("Proposal sender"),
            minWidth: 180,
            flex: 1.8,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return row.proposalSender ? row.ProposalSender.User.fullName : t('Not specified singular')
            }
        },
        {
            field: 'freelanceInterest',
            headerName: t("Interest"),
            minWidth: 180,
            flex: 1.8,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                const status = row.freelanceInterest ? row.freelanceInterest : 'Not specified';
                const label = t(Functions.STATUS_PROPOSAL_MISSION[status]);
                return (
                    <Chip
                        label={label}
                        title={label}
                        size="small"
                        className={Functions.STATUS_PROPOSAL_MISSION_BG[status]}
                        sx={{whiteSpace: "nowrap"}}
                    />
                );
            }
        },
        {
            field: 'action',
            headerName: '',
            minWidth: 60,
            flex: 0.6,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({row}) => {
                return !row.proposalDate && (
                    <IconButton onClick={() => handleDeleteShortlistedFreelance(row.id)} aria-label="delete">
                        <MdDelete/>
                    </IconButton>
                );
            }
        }
    ];

    useEffect(() => {
        if (editMode) {
            setFormErrors({});

            TagService.getMissionTags({}, token).then((fetchedTags) => {
                if (!fetchedTags?.error) {
                    const fetchedTagsData = TagService.groupTagsByType(fetchedTags.data);
                    setTags(fetchedTagsData);
                }
            });
        }
    }, [editMode]);

    useEffect(() => {
        setSectionFormData({...formData});
    }, [formData]);

    useEffect(() => {
        if (token) {
            fetchEnterpriseProfiles({}, token).then((fetchedEnterpriseProfiles) => {
                if (!fetchedEnterpriseProfiles?.error) {
                    const fetchedEnterpriseProfilesData = fetchedEnterpriseProfiles?.data
                        .map(({User, ...rest}) => ({
                            id: rest.id,
                            name: User.fullName
                        }));
                    setMissionManagers(fetchedEnterpriseProfilesData);
                }
            });

            skillService.getAllDefaultSkill(token).then((defaultSkills) => {
                if (!defaultSkills?.error) {
                    defaultSkills = defaultSkills.map((skill) => {
                        return {...skill, group: t(skillService.getSkillTypeLabel(skill.type))};
                    });
                    setSkillsData(defaultSkills);
                }
            });
        }
    }, [token, t, sessionType]);

    useEffect(() => {
        fetchMissionShortlistedFreelancesRef.current(filterConfigs);
    }, [formData.id, filterConfigs]);

    fetchMissionShortlistedFreelancesRef.current = (query = {}) => {
        try {
            if (typeof formData.id !== "undefined" && formData.id && isEnterpriseSession) {
                fetchMissionShortlistedFreelances(formData.id, query, token).then((fetchedData) => {
                    if (!fetchedData?.error) {
                        setRowCount(fetchedData.count);
                        setShortlistedFreelances(fetchedData.data);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setSectionFormData((prevFormData) => Utils.updateNestedField(prevFormData, name, value));
    };

    const handleDeleteShortlistedFreelance = async (id) => {
        await deleteShortlistedFreelance(id, token);
        fetchMissionShortlistedFreelancesRef.current();
    };

    const handleDataGridChange = (filterConfigs) => {
        fetchMissionShortlistedFreelancesRef.current(filterConfigs);
    };

    const handleFreelanceSelection = async (selFreelances) => {
        await saveShortlistedFreelance({
            MissionId: formData.id,
            FreelanceProfileIds: selFreelances.map(selFreelance => selFreelance.id)
        }, token);

        fetchMissionShortlistedFreelancesRef.current(filterConfigs);
        handleFreelanceSelectionDialogClose();
    };

    const handleProposeMissionSaveFinish = (success) => {
        if (success) {
            setShortlistedFreelancesRowSelectionModel([]);
            setSelectedShortlistedFreelances([]);
            fetchMissionShortlistedFreelancesRef.current(filterConfigs);
            handleProposeMissionDialogClose();
        }
    };

    const handleFreelanceSelectionDialogShow = () => {
        if (formData.id) {
            setShowFreelanceSelectionDialog(true);
        } else {
            showToast(
                t("You must complete and save the assignment details to pre-select contingent workers"),
                "warning",
                10000
            );
        }
    };

    const handleFreelanceSelectionDialogClose = () => {
        setShowFreelanceSelectionDialog(false);
    };

    const handleProposeMissionDialogClose = () => {
        setShowProposeMissionDialog(false);
    };

    const handleRowSelectionModelChange = (newRowSelectionModel) => {
        setShortlistedFreelancesRowSelectionModel(newRowSelectionModel);
        setSelectedShortlistedFreelances(
            newRowSelectionModel
                .map((id) => {
                    const matchedItem = shortlistedFreelances.find((item) => item.id === id);
                    return matchedItem ? matchedItem.FreelanceProfileId : null;
                })
                .filter((id) => id !== null)
        );
    };

    const handleSave = async () => {
        if (descriptionRef.current) {
            const editor = descriptionRef.current.getEditor();
            editor.blur();
        }
        const result = onSave && await onSave({...formData, ...sectionFormData});
        if (result?.error) {
            setFormErrors(result.data);
        } else {
            setEditMode(false);
        }
    };

    const handleEditOption = async (option, newLabel) => {
        if (option.value !== option.label) {
            await TagService.modifyTag(option.value, {name: newLabel}, token);
        }
    };

    const handleDeleteOption = async (option) => {
        if (option.value !== option.label) {
            await TagService.deleteTag(option.value, token);
        }
    };

    return <>
        {isEnterpriseSession && !proposal && company?.missionContractInvoiceCreation && (
            <div className="card standardform-card card-padding-wide"
                 style={{padding: "10px 20px", alignItems: "center"}}>
                <Stack direction="column" alignItems="center" spacing={1}>
                    <Typography variant="label">{t("Assignment format")}</Typography>
                    <ToggleButtonGroup
                        color="primary"
                        value={sectionFormData?.format}
                        exclusive
                        onChange={onMissionFormatChange}
                        aria-label={t("Assignment format")}
                        className="mission-format"
                        orientation={isSmallScreen ? 'vertical' : 'horizontal'}
                    >
                        <ToggleButton value={Functions.MISSION_FORMAT.OPERATIONAL} disableRipple>
                            {t("Operational assignment")}
                        </ToggleButton>
                        <ToggleButton value={Functions.MISSION_FORMAT.CONTRACT_WITHOUT_INVOICE} disableRipple>
                            {t("Assignment without invoices")}
                        </ToggleButton>
                        <ToggleButton value={Functions.MISSION_FORMAT.CONTRACT_INVOICE} disableRipple>
                            {t("Assignment with contract and invoices")}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
            </div>
        )}

        <CustomCard
            className="justify-content-center"
            editMode={editMode}
            setEditMode={setEditMode}
            canModify={canModify && missionId}
            onSave={handleSave}
        >
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Assignment name")}
                        type="text"
                        name="name"
                        id="name"
                        defaultValue={sectionFormData?.name}
                        onBlur={handleChange}
                        canModify={editMode}
                        mandatoryFlag={true}
                        error={formErrors.name}
                    />
                </Grid>
            </Grid>
            {proposal
                ? (
                    <Grid container spacing={{xs: 2, md: 3}}>
                        <Grid item xs={12}>
                            <FieldHolder
                                fielddesc={t("Assignment Manager")}
                                type="text"
                                name="customOperationalManager"
                                id="customOperationalManager"
                                defaultValue={sectionFormData?.customOperationalManager}
                                onBlur={handleChange}
                                canModify={editMode}
                            />
                        </Grid>
                    </Grid>
                )
                : (
                    <Grid container spacing={{xs: 2, md: 3}}>
                        <Grid item xs={12} md={4}>
                            <FieldHolder
                                fielddesc={t("Assignment Manager")}
                                type="dropdown"
                                name="operational_manager"
                                id="operational_manager"
                                value={sectionFormData?.operational_manager}
                                datas={missionManagers}
                                formData={sectionFormData}
                                setFormData={setSectionFormData}
                                canModify={editMode}
                                mandatoryFlag={true}
                                error={formErrors.operational_manager}
                            />
                        </Grid>
                        {
                            isEnterpriseSession &&
                            [
                                Functions.MISSION_FORMAT.CONTRACT_INVOICE,
                                Functions.MISSION_FORMAT.CONTRACT_WITHOUT_INVOICE
                            ].includes(sectionFormData?.format) && (
                                <>
                                    <Grid item xs={12} md={4}>
                                        <FieldHolder
                                            fielddesc={t("Contracting manager")}
                                            type="dropdown"
                                            name="hr_contact"
                                            id="hr_contact"
                                            value={sectionFormData?.hr_contact}
                                            datas={missionManagers}
                                            formData={sectionFormData}
                                            setFormData={setSectionFormData}
                                            canModify={editMode}
                                            mandatoryFlag={true}
                                            error={formErrors.hr_contact}
                                        />
                                    </Grid>
                                    {sectionFormData?.format === Functions.MISSION_FORMAT.CONTRACT_INVOICE && (
                                        <Grid item xs={12} md={4}>
                                            <FieldHolder
                                                fielddesc={t("Invoice Manager")}
                                                type="dropdown"
                                                name="invoicing_contact"
                                                id="invoicing_contact"
                                                datas={missionManagers}
                                                value={sectionFormData?.invoicing_contact}
                                                formData={sectionFormData}
                                                setFormData={setSectionFormData}
                                                canModify={editMode}
                                                mandatoryFlag={true}
                                                error={formErrors.invoicing_contact}
                                            />
                                        </Grid>
                                    )}
                                </>
                            )
                        }
                    </Grid>
                )
            }
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12} md={4}>
                    <FieldHolder
                        fielddesc={t("Assignment type")}
                        type="dropdown"
                        name="deadline"
                        id="deadline"
                        set="AssignmentType"
                        value={sectionFormData?.deadline}
                        formData={sectionFormData}
                        setFormData={setSectionFormData}
                        canModify={editMode}
                        mandatoryFlag={!proposal}
                        error={formErrors.deadline}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FieldHolder
                        fielddesc={t("Starting date")}
                        type="date"
                        name="start_date"
                        id="start_date"
                        value={sectionFormData?.start_date}
                        onChange={handleChange}
                        canModify={editMode}
                        mandatoryFlag={!proposal}
                        error={formErrors.start_date}
                    />
                </Grid>
                {(sectionFormData.deadline === Functions.TEXT_CDD || sectionFormData?.estimated_end_date) && (
                    <Grid item xs={12} md={4}>
                        <FieldHolder
                            fielddesc={t("End date")}
                            type="date"
                            name="estimated_end_date"
                            id="estimated_end_date"
                            value={sectionFormData?.estimated_end_date}
                            onChange={handleChange}
                            canModify={editMode}
                            mandatoryFlag={!proposal}
                            error={formErrors.estimated_end_date}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Staffing rate (%)")}
                        type="number"
                        name="estimatedStaffingRate"
                        id="estimatedStaffingRate"
                        value={sectionFormData?.estimatedStaffingRate}
                        min={1}
                        max={100}
                        unit="%"
                        onChange={handleChange}
                        canModify={editMode}
                    />
                </Grid>
            </Grid>
            {!proposal && (
                <Grid container spacing={{xs: 2, md: 3}}>
                    {isEnterpriseSession && (
                        <>
                            <Grid item xs={12} md={4}>
                                <FieldHolder
                                    fielddesc={t("Budget")}
                                    type="number"
                                    name="budget"
                                    id="budget"
                                    value={sectionFormData?.budget}
                                    onChange={handleChange}
                                    canModify={editMode}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FieldHolder
                                    fielddesc={t("Budget currency")}
                                    type="dropdown"
                                    name="budgetCurrency"
                                    id="budgetCurrency"
                                    set="Exchange"
                                    value={sectionFormData?.budgetCurrency}
                                    formData={sectionFormData}
                                    setFormData={setSectionFormData}
                                    canModify={editMode}
                                />
                            </Grid>
                            <Grid item xs={12} md>
                                <FieldHolder
                                    fielddesc={t("Purchase order number")}
                                    type="text"
                                    name="purchaseOrderNumber"
                                    id="purchaseOrderNumber"
                                    defaultValue={sectionFormData?.purchaseOrderNumber}
                                    onBlur={handleChange}
                                    canModify={editMode}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            )}
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Assignment location")}
                        type="text"
                        name="address"
                        id="address"
                        defaultValue={sectionFormData?.address}
                        onBlur={handleChange}
                        canModify={editMode}
                        mandatoryFlag={!proposal}
                        error={formErrors.address}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12}>
                    <FieldHolder
                        ref={descriptionRef}
                        fielddesc={t("Activity deliverable")}
                        type="wysiwyg"
                        name="description"
                        id="description"
                        value={sectionFormData?.description}
                        onBlur={handleChange}
                        canModify={editMode}
                        mandatoryFlag={!proposal}
                        error={formErrors.description}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Final customer")}
                        type="dropdown"
                        name="MissionTags.mission_final_customer"
                        id="MissionTags.mission_final_customer"
                        value={sectionFormData?.MissionTags?.mission_final_customer}
                        datas={tags?.mission_final_customer}
                        formData={sectionFormData}
                        setFormData={setSectionFormData}
                        ismulti={true}
                        canModify={editMode}
                        allowAdd={isSuperAdministrator}
                        allowManageOption={isSuperAdministrator}
                        onEditOption={handleEditOption}
                        onDeleteOption={handleDeleteOption}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={{xs: 2, md: 3}}>
                <Grid item xs={12}>
                    <FieldHolder
                        fielddesc={t("Skills required")}
                        type="dropdown"
                        name="Skills"
                        id="Skills"
                        datas={skillsData}
                        value={sectionFormData?.Skills}
                        formData={sectionFormData}
                        setFormData={setSectionFormData}
                        ismulti={true}
                        canModify={editMode}
                        allowGroup={true}
                    />
                </Grid>
            </Grid>
        </CustomCard>

        {editMode && !missionId && (
            <Stack alignItems="center" className="w-100">
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={handleSave}
                >
                    {t("Save")}
                </Button>
            </Stack>
        )}

        {isEnterpriseSession && proposal && (
            <div className="card standardform-card card-padding-wide">
                <Stack justifyContent="start" sx={{width: "100%"}}>
                    <Typography variant="label">{t("Solicited contingent workers")}</Typography>
                </Stack>

                {canModify && (
                    <Stack direction="row" justifyContent="end" spacing={1} sx={{width: "100%"}}>
                        {selectedShortlistedFreelances.length > 0 &&
                            <>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => setShowProposeMissionDialog(true)}
                                    size="small"
                                >
                                    {t("Propose the assignment")}
                                </Button>

                                <Popup
                                    title={t("Offer the assignment to contingent workers")}
                                    open={showProposeMissionDialog}
                                    onDialogClose={handleProposeMissionDialogClose}
                                >
                                    <Box sx={{width: {md: "25vw"}}}>
                                        <SendProposalAssignmentForm
                                            missionId={missionId}
                                            selectedFreelances={selectedShortlistedFreelances}
                                            onSaveFinish={handleProposeMissionSaveFinish}
                                        />
                                    </Box>
                                </Popup>
                            </>
                        }
                        <>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={handleFreelanceSelectionDialogShow}
                                size="small"
                            >
                                {t(proposal ? "Add a contingent worker" : "Pre-select a contingent worker")}
                            </Button>

                            <FreelanceSelectionPopup
                                open={showFreelanceSelectionDialog}
                                title={t("Select the contingent workers")}
                                onDialogClose={handleFreelanceSelectionDialogClose}
                                values={[]}
                                excludes={shortlistedFreelances.map((item) => item.FreelanceProfile.id)}
                                onSelect={handleFreelanceSelection}
                            />
                        </>
                    </Stack>
                )}

                <Box className="table-layout">
                    <Table
                        className="MuiDataGrid-cell-padding-sm"
                        columns={columns}
                        data={shortlistedFreelances}
                        rowCount={rowCount}
                        pageSize={pageSize}
                        filterConfigs={filterConfigs}
                        setFilterConfigs={setFilterConfigs}
                        checkboxSelection={Boolean(formData.id)}
                        isRowSelectable={(params) => !params.row.proposalDate}
                        onChange={handleDataGridChange}
                        rowSelectionModel={shortlistedFreelancesRowSelectionModel}
                        onRowSelectionModelChange={handleRowSelectionModelChange}
                        fullHeight={true}
                        hideFilter={true}
                    />
                </Box>
            </div>
        )}
    </>
};