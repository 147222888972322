import React, {useEffect, useRef, useState} from "react";
import {FieldHolder} from "../../../FieldHolder";
import {useTranslation} from "react-i18next";
import {Box, Button, Grid} from "@mui/material";
import Utils from "../../../../../utils/Utils";
import {createReferral, sendReferenceAccessRequest, updateReferral} from "../../../../../services/referralService";
import {useFormStore} from "../../../../../store/formStore";
import _ from "lodash";

const ReferralContactAccessForm = ({token, onSaveFinish}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        message: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const formRef = useRef(null);
    const {referenceAccessData, setReferenceAccessData} = useFormStore();

    const requiredFields = ['firstName', 'lastName', 'email', 'companyName'];

    useEffect(() => {
        setData(prevData => _.merge({}, prevData, referenceAccessData));
    }, [referenceAccessData]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setData({...data, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {valid, errors} = Utils.validateRequiredFields(data, requiredFields);
        setFormErrors(errors);

        Utils.handleFieldValidation(formRef, errors);

        if (!valid) {
            return;
        }

        (async () => {
            setLoading(true);

            let response = await sendReferenceAccessRequest(data, token);
            if (!response?.error) {
                setReferenceAccessData({...referenceAccessData, referenceAccessRequestCode: response.code});
            }
            onSaveFinish && onSaveFinish(!response?.error);

            setLoading(false);
        })();
    }

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            noValidate
        >
            <Box sx={{width: {md: "30vw"}, display: "flex", flexDirection: "column", gap: "15px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FieldHolder
                            fielddesc={t("First name")}
                            type="text"
                            name="firstName"
                            id="firstName"
                            value={data.firstName}
                            onChange={handleChange}
                            mandatoryFlag={true}
                            error={formErrors.firstName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldHolder
                            fielddesc={t("Last name")}
                            type="text"
                            name="lastName"
                            id="lastName"
                            value={data.lastName}
                            onChange={handleChange}
                            mandatoryFlag={true}
                            error={formErrors.lastName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldHolder
                            fielddesc={t("Email")}
                            type="text"
                            name="email"
                            id="email"
                            value={data.email}
                            onChange={handleChange}
                            mandatoryFlag={true}
                            error={formErrors.email}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldHolder
                            fielddesc={t("Company")}
                            type="text"
                            name="companyName"
                            id="companyName"
                            value={data.companyName}
                            onChange={handleChange}
                            mandatoryFlag={true}
                            error={formErrors.companyName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FieldHolder
                            fielddesc={t("Message to the contingent worker")}
                            type="multiline"
                            name="message"
                            id="message"
                            rows={2}
                            value={data.message}
                            onChange={handleChange}
                            canModify={true}
                        />
                    </Grid>
                </Grid>
                <div style={{textAlign: "center"}}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                    >{t("Validate")}</Button>
                </div>
            </Box>
        </form>
    );
}

export default ReferralContactAccessForm