import {createTheme} from "@mui/material";

let theme = createTheme({
    typography: {
        fontFamily: 'Poppins, sans-serif',
    },
    palette: {
        primary: {
            main: '#091747',
            light: '#303773',
            dark: '#091747',
        },
        secondary: {
            main: '#F66F81',
        },
        info: {
            main: '#00BAB6',
        },
        light: {
            main: '#b4b4b4',
        },
        warning: {
            main: '#f57742',
        },
        success: {
            main: '#6BCBA0',
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    color: "#ffffff",
                },
                containedSecondary: {
                    color: "#ffffff"
                },
                containedInfo: {
                    color: "#ffffff"
                },
                containedLight: {
                    color: "#ffffff"
                },
                containedWarning: {
                    color: "#ffffff"
                },
                containedSuccess: {
                    color: "#ffffff"
                },
            }
        },
    },
})

theme = createTheme(theme, {
    typography: {
        textPrimary: {
            color: theme.palette.primary.main,
        },
        textInfo: {
            color: theme.palette.info.main,
        },
        textBold: {
            color: theme.palette.primary.main,
            fontFamily: "Poppins-bold",
            display: 'block',
            textAlign: 'left'
        },
        label: {
            color: theme.palette.info.main,
            fontFamily: "Poppins-bold",
            fontSize: '1rem',
            fontWeight: 'bold',
            display: 'block',
            textAlign: 'left'
        },
        labelInfo: {
            color: theme.palette.info.main,
            fontSize: '1rem',
            fontWeight: 'bold',
            display: 'flex',
            textAlign: 'left'
        },
        iconText: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            textAlign: 'left',
        }
    },
});

export default theme