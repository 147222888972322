import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AppLayout} from "../../layouts/AppLayout";
import {Box, Button, IconButton, Stack} from "@mui/material";
import {DisplayBar} from "../../components/UI/DisplayBar";
import {ContingentWorkerCard} from "./ContingentWorkerCard";
import {TalentBoard} from "./TalentBoard";
import {FreelancePopup} from "../../components/Popup/FreelancePopup";
import {useSelector} from "react-redux";
import Functions from "../../utils/Functions";
import PermissionService from "../../services/permissionService";
import {MdOutlineFavorite, MdOutlineFavoriteBorder} from "react-icons/md";
import {useNavigate} from "react-router-dom";

export const PoolBoard = ({viewType = "Card", subViewType = "All"}) => {
    const {t} = useTranslation();
    const connectedUser = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const isEnterpriseSession = connectedUser.sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    const [pageTitle, setPageTitle] = useState("Contingent workers");
    const [rowCount, setRowCount] = useState(0);
    const [triggerApi, setTriggerApi] = useState(false);
    const [freelanceAddingDialog, setFreelanceAddingDialog] = useState(false);

    const displayItems = [
        {key: 'Card', name: 'Cards'},
        {key: 'List', name: 'List'}
    ];

    const handleDisplayMenuClick = (newDisplay) => {
        navigate(`/pool/${newDisplay.toLowerCase()}`);
    };

    const handleFreelanceDialogShow = () => {
        if (
            PermissionService.isGranted(connectedUser, Functions.MODULE.CRM_POOL, [
                Functions.PLAN.ESSENTIAL, Functions.PLAN.STAFFING,
                Functions.PLAN.TALENT, Functions.PLAN.INTEGRAL
            ]) ||
            (connectedUser.plan === Functions.PLAN.PROJECT && rowCount <= 20)
        ) {
            setFreelanceAddingDialog(true);
        }
    };

    const handleFreelanceDialogClose = (reload = false) => {
        setFreelanceAddingDialog(false);
        if (reload) {
            setTriggerApi(true);
        }
    };

    return (
        <AppLayout className="content-full-height">
            <Stack>
                <div className="pageheader">
                    <h1 className="pagetitle">
                        {t(pageTitle)}
                        <p className="header-data-row-count">{rowCount}</p>
                    </h1>
                    <div className="pageaction">
                        {isEnterpriseSession && pageTitle !== "Assignment proposals" && (
                            <>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={handleFreelanceDialogShow}
                                >
                                    {t("Add a contingent worker")}
                                </Button>
                                <FreelancePopup
                                    show={freelanceAddingDialog}
                                    onClose={handleFreelanceDialogClose}
                                    canModify={true}
                                />
                            </>
                        )}
                    </div>
                </div>
                <DisplayBar
                    displayItems={displayItems}
                    displayType={viewType}
                    onDisplayMenuClick={handleDisplayMenuClick}
                    sx={{width: '100%', mb: 1}}
                />
            </Stack>
            <Stack sx={{height: "calc(100% - 100px)"}}>
                <TalentBoard
                    triggerApi={triggerApi}
                    setTriggerApi={setTriggerApi}
                    viewType={viewType}
                    subViewType={subViewType}
                    onListChange={setRowCount}
                    onPageTitleChange={setPageTitle}
                />
            </Stack>
        </AppLayout>
    );
};

export const FavoriteButton = ({id, isFavorite, loading = false, onToggleFavorite}) => {
    const [favorite, setFavorite] = useState(isFavorite);

    useEffect(() => {
        setFavorite(isFavorite);
    }, [isFavorite]);

    const handleButtonClick = async (e) => {
        e.stopPropagation();
        const newFavoriteStatus = !favorite;
        setFavorite(newFavoriteStatus);
        await onToggleFavorite(id, newFavoriteStatus);
    };

    return (
        <IconButton
            color={favorite ? "secondary" : "default"}
            onClick={!loading ? handleButtonClick : null}
            aria-label="favorite"
            size="small"
        >
            {favorite ? <MdOutlineFavorite size={22}/> : <MdOutlineFavoriteBorder size={22}/>}
        </IconButton>
    );
};