import {useTranslation} from "react-i18next";
import {CustomCard} from "../../../../../UI/CustomCard";
import {FieldHolder} from "../../../../FieldHolder";
import Functions from "../../../../../../utils/Functions";
import React, {useEffect, useState} from "react";
import skillService from "../../../../../../services/skillService";
import {useSelector} from "react-redux";
import {handleFreelanceProfileSave, useFreelanceProfileStore} from "../../../../../../store/freelanceProfileStore";
import {Button, Grid, IconButton, Popover, Stack, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {MdClose} from "react-icons/md";

export const FreelanceSkillCard = ({formData, canModify}) => {
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const sessionType = useSelector((state) => state.auth.sessionType);
    const {
        freelanceData,
        setFreelanceData
    } = useFreelanceProfileStore();

    const freelanceProfileData = formData?.id ? formData : freelanceData;

    const [editMode1, setEditMode1] = useState(false);
    const [editMode2, setEditMode2] = useState(false);
    const [skills, setSkills] = useState([]);
    const [helpAnchorEl, setHelpAnchorEl] = useState(null);

    const isFreelanceSession = sessionType === Functions.SESSION_TYPE_FREELANCE;

    useEffect(() => {
        (async () => {
            const defaultSkills = await skillService.getAllDefaultSkill(token);
            const skillsData = skillService.groupSkillsByType(defaultSkills);
            setSkills(skillsData);
        })();
    }, [token]);

    const handleSave = async (callback) => {
        await handleFreelanceProfileSave({
            token,
            freelanceProfileData,
            callback
        })
    };

    const handleHelpClick = (event) => {
        setHelpAnchorEl(event.currentTarget);
    };

    const handleHelpClose = () => {
        setHelpAnchorEl(null);
    };

    const openHelpMenu = Boolean(helpAnchorEl);
    const helpPopoverId = openHelpMenu ? 'help-popover' : undefined;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CustomCard
                    otherContent={(
                        isFreelanceSession && (
                            <Box sx={{mx: canModify ? 1 : 0}}>
                                <Button
                                    aria-describedby={helpPopoverId}
                                    onClick={handleHelpClick}
                                    variant="outlined"
                                    size="small"
                                    sx={{p: "10px !important"}}
                                >
                                    {t("Suggest a skill")}
                                </Button>
                                <Popover
                                    id={helpPopoverId}
                                    open={openHelpMenu}
                                    anchorEl={helpAnchorEl}
                                    onClose={handleHelpClose}
                                    anchorOrigin={{vertical: 'center', horizontal: 'right'}}
                                    transformOrigin={{vertical: 'center', horizontal: 'left'}}
                                    sx={{ml: 1}}
                                >
                                    <Box sx={{p: 2, width: "300px", position: "relative"}}>
                                        <Stack sx={{position: "absolute", top: "8px", right: "8px"}}>
                                            <IconButton onClick={handleHelpClose} size="small">
                                                <MdClose size={12}/>
                                            </IconButton>
                                        </Stack>

                                        <Stack direction="column" gap={1}>
                                            <Typography>{t("Send us your skill suggestions at the following email address: contact@smarteem.com")}</Typography>
                                            <Typography>{t("We will then add them to the list of available skills.")}</Typography>
                                        </Stack>
                                    </Box>
                                </Popover>
                            </Box>
                        )
                    )}
                    editMode={editMode1}
                    setEditMode={setEditMode1}
                    onSave={() => handleSave(() => setEditMode1(false))}
                >
                    <FieldHolder
                        fielddesc={t("Technical skills")}
                        type="dropdown"
                        name="FreelanceProfileSkills.technical"
                        id="FreelanceProfileSkills.technical"
                        value={freelanceProfileData?.FreelanceProfileSkills?.technical}
                        datas={skills[Functions.SKILL_TYPE.TECHNICAL]}
                        formData={freelanceProfileData}
                        setFormData={setFreelanceData}
                        canModify={editMode1}
                        ismulti={true}
                    />
                    <FieldHolder
                        fielddesc={t("Languages")}
                        type="dropdown"
                        name="FreelanceProfileSkills.language"
                        id="FreelanceProfileSkills.language"
                        value={freelanceProfileData?.FreelanceProfileSkills?.language}
                        datas={skills[Functions.SKILL_TYPE.LANGUAGE]}
                        formData={freelanceProfileData}
                        setFormData={setFreelanceData}
                        canModify={editMode1}
                        ismulti={true}
                    />
                    <FieldHolder
                        fielddesc={t("Transversal skills")}
                        type="dropdown"
                        name="FreelanceProfileSkills.transversal"
                        id="FreelanceProfileSkills.transversal"
                        value={freelanceProfileData?.FreelanceProfileSkills?.transversal}
                        datas={skills[Functions.SKILL_TYPE.TRANSVERSAL]}
                        formData={freelanceProfileData}
                        setFormData={setFreelanceData}
                        ismulti={true}
                        canModify={editMode1}
                    />
                    <FieldHolder
                        fielddesc={t("Business lines")}
                        type="dropdown"
                        name="FreelanceProfileSkills.business_line"
                        id="FreelanceProfileSkills.business_line"
                        value={freelanceProfileData?.FreelanceProfileSkills?.business_line}
                        datas={skills[Functions.SKILL_TYPE.BUSINESS]}
                        formData={freelanceProfileData}
                        setFormData={setFreelanceData}
                        ismulti={true}
                        canModify={editMode1}
                    />
                </CustomCard>
            </Grid>
            {/*<Grid item xs={12}>
                <CustomCard
                    editMode={editMode2}
                    setEditMode={setEditMode2}
                    onSave={() => handleSave(() => setEditMode2(false))}
                >
                    <FieldHolder
                        fielddesc={t("Companies")}
                        type="dropdown"
                        name="CompanyDirectories"
                        id="CompanyDirectories"
                        value={freelanceProfileData?.CompanyDirectories}
                        datas={skills[Functions.SKILL_TYPE.BUSINESS]}
                        formData={freelanceProfileData}
                        setFormData={setFreelanceData}
                        ismulti={true}
                        canModify={editMode2}
                    />
                    <FieldHolder
                        fielddesc={t("Business lines")}
                        type="dropdown"
                        name="FreelanceProfileSkills.business_line"
                        id="FreelanceProfileSkills.business_line"
                        value={freelanceProfileData?.FreelanceProfileSkills?.business_line}
                        datas={skills[Functions.SKILL_TYPE.BUSINESS]}
                        formData={freelanceProfileData}
                        setFormData={setFreelanceData}
                        ismulti={true}
                        canModify={editMode2}
                    />
                </CustomCard>
            </Grid>*/}
        </Grid>
    );
};