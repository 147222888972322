import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";

import filesFreelanceProfile from "../../../services/filesFreelanceProfile";

import {ProfileSection} from "./Sections/ProfileSection/ProfileSection";
import {InformationAndDocumentSection} from "./Sections/InformationAndDocumentSection/InformationAndDocumentSection";
import {MissionsSection} from "./Sections/MissionsSection";

import "../form.css";
import "./freelanceprofileform.css";
import Functions from "../../../utils/Functions";
import {AvailabilitySection} from "./Sections/AvailabilitySection";
import {ReferralSection} from "./Sections/ReferralSection";
import SectionMenu from "../../UI/SectionMenu";
import {CompanyFreelanceNote} from "./CompanyFreelance/CompanyFreelanceNote";
import {useFreelanceProfileStore} from "../../../store/freelanceProfileStore";
import {useTranslation} from "react-i18next";
import {fetchCompany} from "../../../services/companyService";
import {OtherAssignmentSection} from "./Sections/OtherAssignmentSection/OtherAssignmentSection";
import {OfferAnswerSection} from "./Sections/OfferAnswerSection";
import PermissionService from "../../../services/permissionService";

export const FreelanceProfileForm = (
    {
        activeSection,
        setActiveSection,
        editPermissions
    }
) => {
    const objectAttachedType = "freelanceProfile";
    const {t} = useTranslation();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const {freelanceApplication, freelanceData, setFreelanceData} = useFreelanceProfileStore();

    const sessionType = useSelector((state) => state.auth.sessionType);
    const companyId = useSelector((state) => state.auth.companyId);

    const [company, setCompany] = useState({});

    const otherAssignmentSectionRef = useRef(null);
    const formRef = useRef(null);

    const canModify = editPermissions.includes(Functions.FREELANCE_PROFILE_PERMISSION.FULL);

    useEffect(() => {
        filesFreelanceProfile.setFiles([]);
        filesFreelanceProfile.setFilesDeleted([]);
    }, []);

    useEffect(() => {
        fetchCompany(companyId, token).then((fetchedCompany) => {
            if (!fetchedCompany?.error) {
                setCompany(fetchedCompany);
            }
        });
    }, [token, companyId]);

    const handleSectionClick = (section) => {
        setActiveSection(section);
        if (section === Functions.FREELANCE_PROFILE_SECTION.OTHER_ASSIGNMENT) {
            otherAssignmentSectionRef.current && otherAssignmentSectionRef.current();
        }
    };

    let sectionMenuItems = [];

    if (sessionType === Functions.SESSION_TYPE_ENTERPRISE) {
        sectionMenuItems = [
            {key: Functions.FREELANCE_PROFILE_SECTION.PROFILE, name: 'Profile'},
            {key: Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY, name: 'Availabilities'},
            {key: Functions.FREELANCE_PROFILE_SECTION.REFERRAL, name: 'External referrals'},
        ];

        if (freelanceApplication?.id) {
            if (PermissionService.hasRole(connectedUser, Functions.USER_ROLE.ADMINISTRATIVE_FINANCIAL)) {
                sectionMenuItems.splice(3, 0, {
                    key: Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT,
                    name: 'Informations and documents'
                });
            }
            sectionMenuItems.splice(4, 0, {
                key: Functions.FREELANCE_PROFILE_SECTION.OFFER_ANSWER,
                name: 'Answers to questions'
            });
        } else if (editPermissions.length) {
            const missionCount = freelanceData?.missionCount;

            sectionMenuItems.splice(2, 0, {
                key: Functions.FREELANCE_PROFILE_SECTION.ASSIGNMENT,
                name: t("Assignments") + (parseInt(missionCount?.smarteem) ? ` (${missionCount?.smarteem})` : ""),
                tooltip: t("Assignments for {{company}}", {company: company?.name})
            });
            sectionMenuItems.splice(3, 0, {
                key: Functions.FREELANCE_PROFILE_SECTION.OTHER_ASSIGNMENT,
                name: t("Assignments outside Smarteem") + (parseInt(missionCount?.other) ? ` (${missionCount?.other})` : ""),
            });
            if (PermissionService.hasRole(connectedUser, Functions.USER_ROLE.ADMINISTRATIVE_FINANCIAL)) {
                sectionMenuItems.splice(5, 0, {
                    key: Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT,
                    name: 'Informations and documents'
                });
            }
        }
    } else {
        sectionMenuItems = [
            {key: Functions.FREELANCE_PROFILE_SECTION.PROFILE, name: 'Profile'},
            {key: Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY, name: 'Availabilities'},
            {key: Functions.FREELANCE_PROFILE_SECTION.REFERRAL, name: 'External referrals'},
            {key: Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT, name: 'Informations and documents'},
        ];
    }

    const standardFormClass = (
        activeSection === Functions.FREELANCE_PROFILE_SECTION.ASSIGNMENT ||
        activeSection === Functions.FREELANCE_PROFILE_SECTION.OTHER_ASSIGNMENT
    ) ? "standardform-w70" : "standardform-w55";

    return (
        <div className="form-layout">
            <div className="workflowmenu">
                <SectionMenu
                    color="info"
                    sectionMenuItems={sectionMenuItems}
                    activeSection={activeSection}
                    onSectionMenuClick={handleSectionClick}
                />
            </div>
            <form
                ref={formRef}
                className={`standardform ${standardFormClass}`}
                id="freelanceProfileForm"
                noValidate
            >
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.PROFILE && (
                    <ProfileSection
                        objectAttachedType={objectAttachedType}
                        editPermissions={editPermissions}
                        canModify={canModify}
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.AVAILABILITY && (
                    <AvailabilitySection
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        editPermissions={editPermissions}
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.REFERRAL && (
                    <ReferralSection
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        canModify={editPermissions?.includes(Functions.FREELANCE_PROFILE_PERMISSION.FULL)}
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.ASSIGNMENT && (
                    <MissionsSection
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        editPermissions={editPermissions}
                        canModify={canModify}
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.OTHER_ASSIGNMENT && (
                    <OtherAssignmentSection
                        ref={otherAssignmentSectionRef}
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        editPermissions={editPermissions}
                        canModify={true}
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.INFORMATION_DOCUMENT && (
                    <InformationAndDocumentSection
                        objectAttachedType={objectAttachedType}
                        formData={freelanceData}
                        setFormData={setFreelanceData}
                        editPermissions={editPermissions}
                        canModify={canModify}
                    />
                )}
                {activeSection === Functions.FREELANCE_PROFILE_SECTION.OFFER_ANSWER && (
                    <OfferAnswerSection
                        applicationData={freelanceApplication}
                    />
                )}
            </form>

            {
                sessionType === Functions.SESSION_TYPE_ENTERPRISE &&
                activeSection === Functions.FREELANCE_PROFILE_SECTION.PROFILE &&
                (Boolean(editPermissions.length) || freelanceApplication?.id) &&
                (
                    <CompanyFreelanceNote
                        activeSection={Functions.FREELANCE_PROFILE_SECTION.PROFILE}
                        freelanceProfile={freelanceData}
                        editPermissions={editPermissions}
                        freelanceApplication={freelanceApplication}
                    />
                )
            }
        </div>
    );
};
