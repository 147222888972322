import React, {useState} from "react";
import {TalentBoard} from "../ContingentWorker/TalentBoard";
import {AppLayout} from "../../layouts/AppLayout";
import {Box, IconButton, Popover, Stack} from "@mui/material";
import {MdQuestionMark} from "react-icons/md";
import {useTranslation} from "react-i18next";

export const SourcingBoard = () => {
    const {t} = useTranslation();

    const [rowCount, setRowCount] = useState(null);
    const [helpAnchorEl, setHelpAnchorEl] = useState(null);

    const handleHelpClick = (event) => {
        setHelpAnchorEl(event.currentTarget);
    };

    const handleHelpClose = () => {
        setHelpAnchorEl(null);
    };

    const openHelpMenu = Boolean(helpAnchorEl);
    const helpPopoverId = openHelpMenu ? 'help-popover' : undefined;

    return (
        <AppLayout className="content-full-height">
            {/*<Stack>
                <div className="pageheader">
                    <h1 className="pagetitle">
                        {t("Finding new freelancers")}
                        <p className={`header-data-row-count ${rowCount === null ? "d-none" : ""}`}>
                            {rowCount || 0}
                        </p>
                        <>
                            <IconButton
                                aria-describedby={helpPopoverId}
                                onClick={handleHelpClick}
                                size="small"
                                sx={{border: '1px solid rgba(0, 0, 0, 0.23)'}}
                            ><MdQuestionMark/></IconButton>
                            <Popover
                                id={helpPopoverId}
                                open={openHelpMenu}
                                anchorEl={helpAnchorEl}
                                onClose={handleHelpClose}
                                anchorOrigin={{vertical: 'center', horizontal: 'right'}}
                                transformOrigin={{vertical: 'center', horizontal: 'left'}}
                                sx={{ml: 1}}
                            >
                                <Box sx={{p: 1.5, width: "300px"}}>
                                    {t("Explore the freelancers registered on Smarteem, consult their profiles and add them to your pool of freelancers.")}
                                </Box>
                            </Popover>
                        </>
                    </h1>
                </div>
                <Box sx={{mt: 1.5}}></Box>
            </Stack>
            <TalentBoard sourcing={true}/>*/}
        </AppLayout>
    );
};