import {requestApi} from "./apiService";

const BASE_URL = process.env.REACT_APP_BASE_URL_API + "/api/missions/";

export const fetchMission = async (id, token) => {
    try {
        return requestApi(`${BASE_URL}${id}`, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching mission:", error);
        throw error;
    }
};

export const fetchMissions = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching mission :", error);
        throw error;
    }
};

export const fetchStaffedFreelances = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}/staffed-freelances?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching staffed freelances :", error);
        throw error;
    }
};

export const fetchProposalMissions = async (query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}proposal?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching proposal missions :", error);
        throw error;
    }
};

export const fetchFreelanceMissions = async (freelanceId, query, token) => {
    try {
        const params = new URLSearchParams(query);
        const url = `${BASE_URL}freelance/${freelanceId}?${params.toString()}`;

        return requestApi(url, 'GET', {}, token);
    } catch (error) {
        console.error("Error fetching mission :", error);
        throw error;
    }
};

export const createMission = async (missionData, token) => {
    try {
        return requestApi(`${BASE_URL}create`, 'POST', missionData, token);
    } catch (error) {
        console.error("Error creating mission:", error);
        throw error;
    }
};

export const duplicateMission = async (missionId, token) => {
    try {
        return requestApi(`${BASE_URL}duplicate/${missionId}`, 'PUT', {}, token);
    } catch (error) {
        console.error("Error duplicating mission:", error);
        throw error;
    }
};

export const updateMission = async (missionId, missionData, token) => {
    try {
        return requestApi(`${BASE_URL}modify/${missionId}`, 'PUT', missionData, token);
    } catch (error) {
        console.error("Error updating mission:", error);
        throw error;
    }
};

export const updateMissionFormat = async (missionId, missionData, token) => {
    try {
        return requestApi(`${BASE_URL}modify-format/${missionId}`, 'PUT', missionData, token);
    } catch (error) {
        console.error("Error updating mission:", error);
        throw error;
    }
};

export const updateMissionStatus = async (missionId, missionData, token) => {
    try {
        return requestApi(`${BASE_URL}modify-status/${missionId}`, 'PUT', missionData, token);
    } catch (error) {
        console.error("Error updating mission:", error);
        throw error;
    }
};

export const deleteMission = async (missionId, token) => {
    try {
        return requestApi(`${BASE_URL}delete/${missionId}`, 'DELETE', {}, token);
    } catch (error) {
        console.error("Error deleting mission :", error);
        throw error;
    }
};