import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Box, Stack} from "@mui/material";
import {DisplayBar} from "../../components/UI/DisplayBar";
import Utils from "../../utils/Utils";
import {useDispatch, useSelector} from "react-redux";
import Functions from "../../utils/Functions";
import {ProposalAssignmentBoard} from "./ProposalAssignmentBoard";
import {ContingentWorkerList} from "./ContingentWorkerList";
import _ from "lodash";
import {saveDisplayConfig} from "../../services/userService";
import {updateDisplayConfig} from "../../utils/actions/authActions";
import {useNavigate} from "react-router-dom";

export const TalentBoard = (
    {
        sourcing = false,
        triggerApi,
        setTriggerApi,
        viewType,
        subViewType,
        onListChange,
        onPageTitleChange,
    }
) => {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const connectedUser = useSelector((state) => state.auth);
    const navigate = useNavigate();

    const pageKey = 'freelance';
    const displayConfig = Object.keys(connectedUser.displayConfig).length
        ? connectedUser.displayConfig : {freelance: {}};
    if (!displayConfig.freelance?.pinnedColumns) {
        displayConfig.freelance.pinnedColumns = {left: ['action', 'User.fullName']}
    }

    const freelanceDisplayConfig = useMemo(() => {
        const config = {...displayConfig.freelance};
        config.viewType = viewType;
        if (sourcing) {
            config.advancedFilter = displayConfig?.sourcing?.advancedFilter ?? {};
            config.filterModel = displayConfig?.sourcing?.filterModel ?? {};
        }
        return config;
    }, [displayConfig, viewType, sourcing]);
    const hasAdvancedFilter = Object.keys(freelanceDisplayConfig?.advancedFilter || {}).length;

    // const [displayType, setDisplayType] = useState(freelanceDisplayConfig?.displayType || subViewType);
    const [displayType, setDisplayType] = useState(subViewType);
    const isEnterpriseSession = connectedUser.sessionType === Functions.SESSION_TYPE_ENTERPRISE;

    const handleDisplayMenuClick = (newDisplay) => {
        /*if (newDisplay !== 'ProposalMission') {
            handleDisplayConfigChange("displayType", newDisplay);
        }*/
        navigate(`/pool/${viewType.toLowerCase()}/${newDisplay.toLowerCase()}`);
    };

    const getDisplayItems = useCallback(() => {
        if (sourcing) return [];
        const baseItems = [{
            key: 'All',
            name: isEnterpriseSession ? 'All contingent workers' : 'List',
            pageTitle: 'Contingent workers'
        }];
        if (isEnterpriseSession) {
            baseItems.splice(1, 0,
                {
                    key: 'MyFavorites',
                    name: 'My favorites',
                    pageTitle: 'My favorites',
                    favorite: 'me'
                },
                {
                    key: 'ProposalMission',
                    name: 'Assignment proposals',
                    pageTitle: 'Assignment proposals'
                }
            );
        }
        return baseItems;
    }, [isEnterpriseSession, sourcing]);

    const handleDisplayConfigChange = useCallback((displayKey, displayValue) => {
        let updatedPageKey = pageKey;

        if (displayKey === 'columnVisibilityModel') {
            displayValue = _.pickBy(displayValue, value => value === false);
        } else if (sourcing && ['advancedFilter', 'filterModel'].includes(displayKey)) {
            updatedPageKey = 'sourcing';
        }

        const updatedDisplayConfig = {
            ...displayConfig,
            [updatedPageKey]: {
                ...displayConfig[updatedPageKey],
                [displayKey]: displayValue,
            },
        };

        saveDisplayConfig(updatedDisplayConfig, token).then((result) => {
            if (!result?.error) {
                dispatch(updateDisplayConfig(updatedDisplayConfig));
            }
        });
    }, [displayConfig, sourcing, token]);

    const activeDisplay = useMemo(() => {
        const items = getDisplayItems();
        return items[Utils.findIndexByKey(items, displayType)];
    }, [displayType]);
    const pageTitle = activeDisplay?.pageTitle;

    useEffect(() => {
        setTriggerApi(true);
        setDisplayType(subViewType);
    }, [subViewType]);

    useEffect(() => {
        setTriggerApi(!hasAdvancedFilter);
    }, [hasAdvancedFilter]);

    useEffect(() => {
        onPageTitleChange?.(pageTitle);
    }, [pageTitle]);

    return (
        <Stack className="h-100">
            <DisplayBar
                displayItems={getDisplayItems()}
                displayType={displayType}
                onDisplayMenuClick={handleDisplayMenuClick}
            />
            <Box className="table-layout">
                {displayType === "ProposalMission"
                    ? (
                        <ProposalAssignmentBoard onListChange={onListChange}/>
                    )
                    : (
                        <ContingentWorkerList
                            sourcing={sourcing}
                            freelanceDisplayConfig={freelanceDisplayConfig}
                            activeDisplay={activeDisplay}
                            setDisplayType={setDisplayType}
                            triggerApi={triggerApi}
                            setTriggerApi={setTriggerApi}
                            onListChange={onListChange}
                            onDisplayConfigChange={handleDisplayConfigChange}
                        />
                    )
                }
            </Box>
        </Stack>
    );
};